<template>
  <b-modal
    v-model="showModal"
    cancel-variant="outline-secondary"
    scrollable
    no-close-on-backdrop
    no-close-on-esc
    centered
    ok-only
    ok-title="Fechar"
    size="lg"
    title="Comentários"
    @ok="closeModal"
  >
    <h4>Lista de todos os comentários do usuário: {{ getName() }}.</h4>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="comments"
    >
      <template
        #cell(luck_number)="data"
      >
        <b-badge v-if="data.item.luck_number.full_number === info.number" variant="success">
          <feather-icon
            icon="StarIcon"
            class="mr-25"
          />
          {{ data.item.luck_number.full_number }}
        </b-badge>
        <span v-else>
          {{ data.item.luck_number.full_number }}
        </span>
      </template>

      <template
        #cell(date)="data"
      >
        {{ data.item.comment_date_pt_br }}
      </template>

      <template
        #cell(comment)="data"
      >
        {{ data.item.comment }}
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { BTable } from 'bootstrap-vue'
import moment from 'moment-timezone'

export default {
  name: 'PrizeDrawCommentsByPerson',
  directives: {
    Ripple,
  },
  components: {
    BTable,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    information: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showModal: false,
      info: {},
      currentPerson: {},
      currentPrizeDraw: {},
      comments: [],
      fields: [
        {
          key: 'luck_number',
          label: 'Número',
          class: 'w-10',
        },
        {
          key: 'date',
          label: 'Data',
          class: 'w-20',
        },
        {
          key: 'comment',
          label: 'Comentário',
          class: 'w-70',
        },
      ],
    }
  },
  watch: {
    isOpen(value) {
      this.showModal = value
    },
    async information(dataPerson) {
      this.info = dataPerson
      this.currentPerson = dataPerson.person
      this.loadData()
    },
  },
  async mounted() {
    const prizeDraw = this.$user.getCurrentPrizeDraw()
    if (!prizeDraw) {
      await this.$router.push(`/change-promotions?ref=${this.$route.path}`)
      return
    }
    this.currentPrizeDraw = prizeDraw
  },
  methods: {

    loadData() {
      if (!this.currentPerson) {
        return
      }

      this.$http.get(`/prize-draws/${this.currentPrizeDraw.uuid}/person/${this.currentPerson.uuid}/comments`).then(res => {
        this.comments = res.comments.map(comment => {
          comment.comment_date_pt_br = moment(comment.comment_date).format('DD/MM/YYYY HH:mm:ss')
          if (this.currentPerson.name === '') {
            this.currentPerson.name = comment.username
          }
          return comment
        })
      })
    },
    closeModal() {
      this.showModal = false
      this.$emit('close', false)
    },
    getName() {
      return this.currentPerson && this.currentPerson.name ? this.currentPerson.name : ''
    },
  },
}
</script>

<style scoped>

</style>
