<template>
  <div>
    <Grid
      :fields="fields"
      :items="items"
      label="Sorteios"
      edit-button-label="edit"
      new-button-label="new"
      :show-search="false"
      @onChangePage="getNumbers"
    >
      <template
        #cell(name)="data"
        style="width: 20%"
      >
        <div class="">

          <b>Sorteio: </b>{{ data.item.name }}<br>
          <b>Data do Sorteio: </b>{{ data.item.draw_date | moment('DD/MM/YYYY') }}<br>
          <b>Números validos para: </b>{{ data.item.numbers_valid_for === 1 ? 'Sorteio' : 'Promoção' }}<br>
        </div>
      </template>
      <template
        #cell(total_numbers)="data"
        style="width: 20%"
      >
        <div class="text-left" v-html="showNumbers(data.item.luck_numbers) "/>
      </template>
      <template
        #cell(action)="data"
        style="width: 30%"
      >
        <div class="">

          <i2-icon-button
            icon="EyeIcon"
            class="mr-1"
            variant="outline-info"
            @click="showNumbersPopup(data.item)"
          />
          <i2-icon-button
            v-if="mustShowCommentButton(data.item)"
            fa-icon="fa-comment-dots"
            variant="outline-info"
            @click="showComments(data.item)"
          />
        </div>
      </template>
    </Grid>
    <b-modal
      id="prize_draw_numbers"
      ref="prize_draw_numbers"
      cancel-variant="outline-secondary"
      scrollable
      centered
      ok-only
      ok-title="Fechar"
      size="sm"
      style="height: 80%"
      title="Números do Sorteio"
    >
      <div>
        <span v-for="(number, i) of currentPrizeDrawNumbers"
              :key="number"
              style="cursor: pointer" @click="showNumberDetail(number)"
        >
          <u>{{ number }}</u><span v-if="i !== currentPrizeDrawNumbers.length -1">, </span>
        </span>

      </div>
    </b-modal>

    <prize-draw-comments-by-person
      :is-open="showCommentsModal"
      :information="contentCommentsModal"
      @close="closeCommentsModal"
    />
    <participant-number-list-detail :show.sync="showModalNumber"
                                    :participant="participantUuid" :prize-draw-uuid="currentPrizeDraw"
                                    @onClose="onCloseNumberModal"
    />
  </div>
</template>
<script>
import PrizeDrawCommentsByPerson from '@/views/prize-draw/PrizeDrawCommentsByPerson'
import ParticipantNumberListDetail from '@/views/participants/ParticipantNumberListDetail'

export default {
  name: 'ParticipantNumberList',
  components: { PrizeDrawCommentsByPerson, ParticipantNumberListDetail },
  props: {
    participant: {
      type: String,
      required: true,
    },
    promotion: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentPrizeDrawNumbers: [],
      search: {
        search: '',
        type: '',
      },
      participantUuid: '',
      isShowNumberList: false,
      currentPrizeDraw: '',
      showModalNumber: false,
      showCommentsModal: false,
      currentNumber: '',
      contentCommentsModal: {},
      fields: [
        {
          key: 'name',
          label: 'Sorteio',
          class: 'w-35',
          thClass: 'text-center',
        },
        {
          key: 'total_numbers',
          label: 'números',
          class: 'w-50',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          key: 'action',
          label: 'Ações',
          class: 'w-10',
        },
      ],
      items: [],
    }
  },
  watch: {
    participant(value) {
      if (value === '' || value === null) {
        return
      }
      this.participantUuid = value
      this.getNumbers(1)
    },
  },
  mounted() {
    if (this.participant === '' || this.participant === null) {
      return
    }
    this.getNumbers(1)
  },
  methods: {
    async getNumbers(page) {
      const promotionUuid = this.promotion
      const { participant } = this
      const route = `promotions/${promotionUuid}/participants/${participant}/numbers`
      this.items = await this.$search.get(
        route,
        page,
        this.search,
        '',
      )
    },
    showNumbers(numbersList) {
      const totalNumbers = numbersList.length

      if (totalNumbers === 0) {
        return 'Não participa desse sorteio'
      }
      if (totalNumbers > 3) {
        let numberListAsString = numbersList.slice(0, 3).join(', ')
        numberListAsString = `${numberListAsString} + (<b>${(totalNumbers - 3)} números</b>) `
        return numberListAsString
      }

      return numbersList.join(', ')
    },
    showNumbersPopup(prizeDraw) {
      this.currentPrizeDraw = prizeDraw.uuid
      this.showModalNumber = true
    },
    mustShowCommentButton(data) {
      return !!(data.prize_draw.promotion.settings.is_instagram_promotion
          && data.prize_draw.promotion.settings.is_instagram_promotion === true)
    },
    showComments() {
      this.showCommentsModal = true
      this.contentCommentsModal = {
        number: '',
        person: {
          uuid: this.participant,
          name: '',
        },
      }
    },
    closeCommentsModal() {
      this.showCommentsModal = false
      this.contentCommentsModal = {}
    },
    showNumberDetail(number) {
      this.currentNumber = number
      this.showModalNumber = true
    },
    onCloseNumberModal() {
      this.currentPrizeDraw = ''
      this.showModalNumber = false
    },
  },
}
</script>

<style scoped>

</style>
