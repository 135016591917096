<template>
  <b-container>
    <b-modal
      v-model="showModal"
      :title="title"
      cancel-title="Fechar"
      no-close-on-backdrop
      ok-title="Fechar"
      size="sm"
      scrollable
      ok-only
      @change="onHide"
    >
      <b-card>
        <b-row>
          <b-col
            cols="12"
          >
            <table class="mt-2 mt-xl-0 w-100">
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="InfoIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Série</span>
                </th>
                <td class="pb-50">
                  {{ number.serie }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="InfoIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Número</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ number.number }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="KeyIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Identificador</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ number.code }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="KeyIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Grupo</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ number.group_uuid }}
                </td>
              </tr>
              <tr>
                <th>
                  <feather-icon
                    icon="CalendarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Data de Geração</span>
                </th>
                <td>
                  {{ number.created_at }}
                </td>
              </tr>
              <tr v-if="number.coupon_uuid != ''">
                <th>
                  <feather-icon
                    icon="NewIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Cupom Fiscal</span>
                </th>
                <td>
                  <a href="#" @click="openCouponModal(number.coupon_uuid);return false">Detalhes do Cupom</a>
                </td>
              </tr>
              <tr v-for="data of number.extra_data" :key="data.name">
                <th>
                  <feather-icon
                    icon="InfoIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ data.label }}</span>
                </th>
                <td>
                  {{ data.value }}
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
    <coupon-detail-modal :coupon-uuid="currentCoupon" :show.sync="showModalCoupon" @onClose="closeCouponModal"/>
  </b-container>
</template>
<script>
import CouponDetailModal from '@/components/app/coupons/CouponDetailModal'

export default {
  name: 'NumberDetailModal',
  components: {
    CouponDetailModal,
  },
  props: {
    fullNumber: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      currentCoupon: '',
      showModalCoupon: false,
      showModal: false,
      title: '',
      number: {},
    }
  },
  watch: {
    async show(value) {
      if (value) {
        await this.getNumberInfo()
        this.title = `Detalhe do Número ${this.fullNumber}`
      }
      this.showModal = value
    },

  },
  async mounted() {
    const promotion = this.$user.getCurrentPromotion()
    if (!promotion) {
      await this.$router.push(`/change-promotions?ref=${this.$route.path}`)
      return
    }
    this.currentPromotion = promotion
  },
  methods: {
    onHide() {
      this.$emit('onClose', true)
    },

    async getNumberInfo() {
      this.number = await this.$http.get(`promotions/${this.currentPromotion.uuid}/number/${this.fullNumber}`)
    },

    openCouponModal(uuid) {
      this.currentCoupon = uuid
      this.showModalCoupon = true
    },

    closeCouponModal() {
      this.currentCoupon = null
      this.showModalCoupon = false
    },
  },
}
</script>

<style scoped>

</style>
