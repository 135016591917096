<template>
  <b-card-code title="Historico">
    <app-timeline>
      <app-timeline-item v-for="(item, i) of items"
                         :key="i"
                         :title="item.name"
                         :subtitle="item.event"
                         icon="AwardIcon"
                         :time="item.date"
                         variant="success"
      />

    </app-timeline>
  </b-card-code>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: 'ParticipantPromotionHistory',
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    participant: {
      type: String,
      required: true,
    },
    promotion: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      items: [],
    }
  },
  watch: {
    participant(value) {
      if (value === '' || value === null) {
        return
      }
      this.geHistory()
    },
  },
  mounted() {
    if (this.participant === '' || this.participant === null) {
      return
    }
    this.geHistory()
  },
  methods: {
    async geHistory() {
      const promotionUuid = this.promotion
      const { participant } = this
      const route = `promotions/${promotionUuid}/participants/${participant}/history`
      const items = await this.$http.get(route)
      this.items = items.map(item => ({
        name: item.prize_draw !== '' ? item.prize_draw : item.promotion,
        event: item.event,
        date: item.time,
      }))
    },
  },
}
</script>

<style scoped>

</style>
