<template>
  <div>
    <b-modal id="prize_draw_numbers"
             ref="prize_draw_numbers"
             v-model="showModal"
             cancel-variant="outline-secondary"
             scrollable
             centered
             ok-only
             ok-title="Fechar"
             size="lg"
             style="height: 80%"
             title="Números do Sorteio"
             @change="onHide"
    >
      <Grid
        :fields="fields"
        :items="items"
        edit-button-label="edit"
        new-button-label="new"
        :show-search="false"
        @onChangePage="getNumbers"
      >

        <template
          #cell(name)="data"
          style="width: 20%"
        >
          <div class="">

            <b>Sorteio: </b>{{ data.item.name }}<br>
            <b>Data do Sorteio: </b>{{ data.item.draw_date | moment('DD/MM/YYYY') }}<br>
            <b>Números validos para: </b>{{ data.item.numbers_valid_for === 1 ? 'Sorteio' : 'Promoção' }}<br>
          </div>
        </template>
        <template
          #cell(action)="data"
          style="width: 30%"
        >
          <div class="">

            <i2-icon-button
              icon="EyeIcon"
              class="mr-1"
              variant="outline-info"
              @click="showNumberDetail(data.item.full_number)"
            />
          </div>
        </template>
      </Grid>
    </b-modal>
    <number-detail-modal :show.sync="showModalNumber" :full-number="currentNumber" @onClose="onCloseNumberModal"/>
  </div>
</template>
<script>
import NumberDetailModal from '@/components/app/numbers/NumberDetailModal.vue'

export default {
  name: 'ParticipantNumberListDetail',
  components: { NumberDetailModal },
  props: {
    participant: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
    prizeDrawUuid: {
      type: String,
      required: true,
    },

  },
  data() {
    return {
      search: {
        search: '',
        type: '',
      },
      showModal: false,
      showModalNumber: false,
      currentNumber: '',
      contentCommentsModal: {},
      fields: [
        {
          key: 'serie',
          label: 'Série',
          class: 'w-5',
          thClass: 'text-center',
          sortable: true,
        },
        {
          key: 'number',
          label: 'Número',
          class: 'w-10',
          thClass: 'text-center',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          key: 'full_number',
          label: 'Número Completo',
          class: 'w-10',
          thClass: 'text-center',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'Data de geração',
          class: 'w-10',
          thClass: 'text-center',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          key: 'action',
          label: 'Ações',
          class: 'w-10',
        },
      ],
      items: [],
    }
  },
  watch: {
    show(value) {
      this.showModal = value

      if (this.participant === '' || this.prizeDrawUuid === '') {
        return
      }

      if (value) {
        this.getNumbers(1)
      }
    },
  },
  mounted() {
    if (this.show) {
      this.getNumbers(1)
    }
  },
  methods: {
    async getNumbers(page) {
      const route = `/participants/${this.participant}/prize-draws/${this.prizeDrawUuid}/luck-numbers`
      this.items = await this.$search.get(
        route,
        page,
        this.search,
        '',
      )
    },

    showNumberDetail(number) {
      this.currentNumber = number
      this.showModalNumber = true
    },
    onCloseNumberModal() {
      this.currentNumber = ''
      this.showModalNumber = false
    },
    onHide() {
      this.$emit('onClose', true)
    },
  },
}
</script>

<style scoped>

</style>
