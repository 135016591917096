<template>
  <b-modal
    id="validateModal"
    v-model="showModal"
    centered
    no-close-on-backdrop
    cancel-title="Reprovar"
    ok-title="fechar"
    scrollable
    ok-only
    @change="onHide"
  >
    <coupon-detail :current-participant="coupon"/>
  </b-modal>
</template>

<script>
import CouponDetail from '@/components/app/coupons/CouponDetail'

export default {
  name: 'CouponDetailModal',
  components: {
    CouponDetail,
  },
  props: {
    couponUuid: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      title: '',
      coupon: {
        name: '',
        file_url: '',
        number_coupon: '',
        value_document: '',
        serie_coupon: '',
        document: '',

      },
    }
  },
  watch: {
    async show(value) {
      if (value) {
        await this.getCouponInfo()
      }
      this.showModal = value
    },

  },
  async mounted() {
    const promotion = this.$user.getCurrentPromotion()
    if (!promotion) {
      await this.$router.push(`/change-promotions?ref=${this.$route.path}`)
      return
    }
    this.currentPromotion = promotion
    await this.getCouponInfo()
  },
  methods: {
    onHide() {
      this.$emit('onClose', true)
    },

    async getCouponInfo() {
      if (!this.couponUuid) {
        return
      }
      this.coupon = await this.$http.get(`promotions/${this.currentPromotion.uuid}/coupons/${this.couponUuid}`)
    },
  },
}
</script>

<style scoped>

</style>
